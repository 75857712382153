import {
    Box,
    Button,
    DarkMode,
    Heading,
    LightMode,
    Stack,
    Text,
} from '@chakra-ui/react'

import Image from 'next/image'
import Link from 'next/link'
import { TypeAnimation } from "react-type-animation"
import { useCanStartNewTrial } from '../../../../shared/hooks/pricing'

export const HeroHome = ({ subheading, opts = {} }) => {
    const canStartNewTrial = useCanStartNewTrial()
    return (
        <DarkMode>
            <Box as="section" color={opts?.color || "fg.default"}>
                <Box
                    maxW={{ base: 'xl', md: '5xl' }}
                    mx="auto"
                    px={{ base: '6', md: '8' }}
                >
                    <Box textAlign="center">
                        <Box display={{ base: "none", md: "block" }}>
                            <TypeAnimation
                                preRenderFirstString
                                sequence={[
                                    0,
                                    'Website planning', // initially rendered starting point
                                    1500,
                                    'Visual sitemaps',
                                    1500,
                                    'User journeys',
                                    1000
                                ]}
                                speed={50}
                                style={{ fontSize: '60px', fontWeight: '800' }}
                                repeat={Infinity}
                            />
                        </Box>
                        <Heading
                            display={{ base: "block", md: "none" }}
                            as="h1"
                            size="3xl"
                            fontWeight="extrabold"
                            maxW="48rem"
                            mx="auto"
                            lineHeight="1.2"
                            letterSpacing="tight"
                        >
                            Website Planning
                        </Heading>
                        <Heading
                            as="h1"
                            size="3xl"
                            fontWeight="extrabold"
                            maxW="48rem"
                            mx="auto"
                            lineHeight="1.2"
                            letterSpacing="tight"
                        >
                            made easy
                        </Heading>
                        {subheading && (
                            <Text fontSize={{ base: "lg", md: "xl" }} mt="6" maxW="2xl" mx="auto">
                                {subheading}
                            </Text>
                        )}
                    </Box>

                    <Stack
                        justify="center"
                        direction={{
                            base: 'column',
                            md: 'row',
                        }}
                        mt="10"
                        mb="20"
                        spacing="4"
                    >
                        <LightMode>
                            <Button
                                size={{ base: "xl", md: "2xl" }}
                                px={9}
                                fontWeight="bold"
                                fontSize={{ base: "lg", md: "xl" }}
                                color="blue.600"
                                bgColor="white"
                            >
                                <Link href={canStartNewTrial ? "/app/upgrade-modal?planId=pro" : "/app/signup?hero=1"} passHref style={{ width: '100%' }}>
                                    {canStartNewTrial ? 'Start free trial' : 'Get started free'}
                                </Link>
                            </Button>
                            <Button
                                size={{ base: "xl", md: "2xl" }}
                                px={9}
                                fontWeight="bold"
                                fontSize={{ base: "lg", md: "xl" }}
                                bgColor="whiteAlpha.200"
                                _hover={{ bgColor: "white", color: "blue.600" }}
                            >
                                <Link href="/app" style={{ width: '100%' }}>
                                    Login
                                </Link>
                            </Button>
                        </LightMode>
                    </Stack>

                    <Box
                        className="group"
                        cursor="pointer"
                        position="relative"
                        rounded="lg"
                        overflow="hidden"
                    >
                        <Image
                            width="960"
                            height="590"
                            alt="Screenshot of Rarchy App"
                            src="/images/heroes/comments.webp"
                            rel="preload"
                            priority="true"
                            fetchpriority="high"
                        />
                    </Box>
                </Box>
            </Box>
        </DarkMode>
    )
}